import { vr, currentPage, initNewSection } from '../common/layout';
import {
  banners,
  commentsCountUpdate,
  hitPageView,
  checkAndFilterCoralCommentsAmountToShow,
} from '../common/utils/fn';
import { getLoadMoreContent } from '../common/utils/load-more-btn-new.-loader';
import 'jquery-scrolltie';
import { getNewsletterWidget } from '../common/widgets/widget-newsletter-loader';

import { getCoralCommentsCountUpdate } from '../common/utils/coral-requests-loader';
import '../common/jquery-snap-scroll';

const initializeFindVehicleWidget = async () => {
  const findVehicleEuWidget = document.querySelector('.m1-find-vehicle-eu');
  const findVehicleUsWidget = document.querySelector('.m1-find-vehicle-us');
  if (findVehicleEuWidget) {
    const { getM1FindVehicleEu, getM1FindVehicleByType } = await import(
      '../common/shop/m1-find-vehicle-widget-loader'
    );
    getM1FindVehicleEu().then((api) => api.m1FindVehicleEu());
    getM1FindVehicleByType().then((api) => api.m1FindVehicleByType());
  } else if (findVehicleUsWidget) {
    const { getM1FindVehicleByType, getM1FindVehicleUs } = await import(
      '../common/shop/m1-find-vehicle-widget-loader'
    );
    getM1FindVehicleUs().then((api) => api.m1FindVehicleUs());
    getM1FindVehicleByType().then((api) => api.m1FindVehicleByType());
  }
};

// Intersection Observer to initialize widgets when visible
const observeVisibility = (selector, callback) => {
  const elements = document.querySelectorAll(selector);
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        callback(entry.target);
        observer.unobserve(entry.target); // Unobserve once initialized
      }
    });
  });

  elements.forEach((element) => observer.observe(element));
};

currentPage.pageInit = ($page, pageData, pageId) => {
  let loadMoreButton = document.querySelector('.m1-pager--next .m1-button');
  loadMoreButton?.addEventListener('click', (event) => {
    event.preventDefault();
    getLoadMoreContent().then((api) => {
      api.loadMoreButtonNew(loadMoreButton);
    });
  });

  $('.msnt-items-slider').snapScrollControl();

  let currentURL = window.location.href;
  var url = new URL(currentURL);
  var searchLoginM1 = url.searchParams.get('is_comment_login');
  if (searchLoginM1) {
    if (searchLoginM1 == '1') {
      window.close();
    }
  }

  commentsCountUpdate();
  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });

  observeVisibility('.m1-find-vehicle-eu', initializeFindVehicleWidget);
  observeVisibility('.m1-find-vehicle-us', initializeFindVehicleWidget);

  getNewsletterWidget().then((api) => {
    api.newsletter();
  });

  checkAndFilterCoralCommentsAmountToShow();
};

currentPage.pageLoadMore = ($html, $btnBox) => {
  initNewSection($html);

  let loadMoreButton = document.querySelector('.m1-pager--next .m1-button');
  loadMoreButton?.addEventListener('click', (event) => {
    event.preventDefault();
    getLoadMoreContent().then((api) => {
      api.loadMoreButtonNew(loadMoreButton);
    });
  });

  observeVisibility('.m1-find-vehicle-eu', initializeFindVehicleWidget);
  observeVisibility('.m1-find-vehicle-us', initializeFindVehicleWidget);

  getCoralCommentsCountUpdate().then((api) => {
    api.coralCommentsCountUpdate();
  });
  banners($html);
  commentsCountUpdate(true);
  hitPageView(document.location.pathname);
  checkAndFilterCoralCommentsAmountToShow();
};
